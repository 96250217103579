$(function() {
    let headerEl = document.querySelector('.header');
    if (!headerEl) return;

    let body = document.querySelector('body');

    let menuShowEl = headerEl.querySelector('.js-menu-show');
    menuShowEl.addEventListener('click', function () {
        if (headerEl.classList.contains('open')) {
            headerEl.classList.remove('open');
            body.classList.remove('menu-show');
        } else {
            headerEl.classList.add('open');
            body.classList.add('menu-show');
        }
    });
})
