$(document).ready(function(){
    $('.about__button').click(function(){
        $('.about__content-hide').slideToggle(300, function(){
            if ($(this).is(':hidden')) {
                $('.about__button').html('Показать весь текст');
                $('.about__button').removeClass('open');
                $('.about__button').next().removeClass('active');
            } else {
                $('.about__button').html('Скрыть весь текст');
                $('.about__button').addClass('open');
                $('.about__button').next().addClass('active');
            }
        });
        return false;
    });
});
